import tips from "./tips";
import Constant from "../config/global";
import LoginUser, {User} from "../store/loginUser";
import * as H from "history";
import {useEffect} from "react";

export function CurrentUrl(): string {
    return window.location.href
}

export function OpenWeb(history: H.History, url: string, state: { toIndex?: boolean }) {
    setTimeout(() => {
        if (state?.toIndex) {
            window.history.replaceState("", "", Constant.IndexPage)
        }
        history.push(url, state)
    }, 200)
}

export function OpenWeb501(code?: string | null) {
    tips.error("渠道或用户信息不正确", false, () => window.location.href = "/501?code=" + code)
}

export function OpenWebLogin() {
    tips.error("登录信息已失效", false, () => {
        window.location.href = Constant.LoginPage
    })

}

// 进入首页
export function SwitchPage(loginUser: User, history: H.History) {
    const {into, unInto, onlyInto, token} = loginUser;
    if (token) {
        if (into) {
            if (onlyInto) {
                history.push("/results")
            } else {
                history.push(Constant.IndexPage)
            }
        } else if (unInto) {
            if (onlyInto) {
                history.push("/results")
            } else {
                history.push("/whole")
            }
        } else {
            history.push("/identity")
        }
    } else {
        tips.error("登录信息已失效", false, () => window.location.href = Constant.LoginPage)
    }
}


export function ScrollGather() {
    setTimeout(function () {
        let count = 1;
        const interval = setInterval(() => {
            const oldScroll = document.getElementById('gather')?.scrollTop;
            document.getElementById('gather')?.scrollTo({top: (oldScroll || 0) + count++})

            if (oldScroll === document.getElementById('gather')?.scrollTop) {
                clearInterval(interval)
            }
        }, 1)

        setTimeout(function () {
            clearInterval(interval)
        }, 1000)

    }, 200);
}

export function OpenProduct(history: H.History, state: { phone?: string, linkUrl: string, toIndex?: boolean, id?: string }) {
    setTimeout(() => {
        if (state.toIndex) {
            window.history.replaceState("", "", Constant.IndexPage)
        }
        window.location.href = state.linkUrl;

    }, 200)

}

export function LoginUserWeb() {
    const query = decodeURIComponent(window.location.search).substring(1);
    let arry = query.split("&");
    let params = {}
    arry.forEach(item => {
        let key = item.substring(0, item.indexOf('=')).toString()
        let val = item.substring(item.indexOf('=') + 1)
        // @ts-ignore
        params[key] = val
    })
    return params;
}
